import React from 'react';
import styled from 'styled-components';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { TextAndImage } from '@beelineloans/cx-library/src/components/layout/panels';
import { H2, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import Phone from '@beelineloans/cx-library/src/images/phone-tracker.png';
import LG1 from '@beelineloans/cx-library/src/images/staff/loan-guide-1.png';
import LG2 from '@beelineloans/cx-library/src/images/staff/loan-guide-2.png';
import LG3 from '@beelineloans/cx-library/src/images/staff/loan-guide-3.png';

const TextAndImageLG = styled(TextAndImage)`
	.container-inner {
		padding-bottom: 75px;
	}

	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		.container-inner {
			padding-bottom: 100px;
		}
	}
`;

const TextAndPhone = styled(TextAndImage)`
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		${H2} {
			padding-top: 150px;
		}
	}
`;

const LoanGuide = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO
      path={CONST.LINKS.MAIN.LOAN_GUIDE}
      title="Meet our Loan Guides"
      description="Living, breathing people. Not smiley stock photos."
    />
    <TextAndImageLG
      image={{
        src: LG1,
        mobileWidth: '315px',
        desktopWidth: '445px'
      }}
      heading="Living, breathing people. Not smiley stock photos."
    >
      <Paragraph>
        They can hold your hand every step of the way or just be there to bounce things off — up to you.
      </Paragraph>
      <Paragraph>
        Your Loan Guide is with you from the moment you apply until closing
        day. <br />
        <br />
        But most importantly, you’ll never feel alone.
      </Paragraph>
    </TextAndImageLG>
    <TextAndImageLG
      heading="Retelling your story to different people over and over sucks."
      image={{
        src: LG2,
        mobileWidth: '315px',
        desktopWidth: '445px'
      }}
      left
    >
      <Paragraph>
        Get in touch with your Loan Guide directly by phone, text, email or DM
        — whatever suits you best.
        <br />
        <br />
        They won’t bug you with calls either.
      </Paragraph>
    </TextAndImageLG>
    <TextAndImageLG
      heading="No pressure. Just peace of mind."
      image={{
        src: LG3,
        mobileWidth: '315px',
        desktopWidth: '445px'
      }}
    >
      <Paragraph>
        Our Loan Guides won’t treat you like a walking dollar sign.
        <br />
        <br />
        They’re paid to guide you to your closing with speed and peace of mind. That’s what’s in it for them.
      </Paragraph>
    </TextAndImageLG>
    <TextAndPhone
      heading="This little guy holds your other hand — it’s your Beeline Tracker."
      image={{
        src: Phone,
        mobileWidth: '309px',
        desktopWidth: '374px'
      }}
      backgroundColor={Theme.colours.background.yellow}
    >
      <Paragraph>
        Get real-time progress updates and know exactly what to do and what to
        expect next.
      </Paragraph>
      <Paragraph>You’ll never be wondering WTF is going on.</Paragraph>
    </TextAndPhone>
  </NavOnlyTemplate>
);

export default LoanGuide;
